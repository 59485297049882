import { Injectable } from "@angular/core"
import { SocialLinkWebService } from "./web-services/social-link-web.service"
import { SocialLink } from "../data/SocialLink"

@Injectable()
export class SocialLinkService {
  constructor(
    private socialLinkWebService: SocialLinkWebService
  ) {
  }

  public getSocialLinks(): Promise<SocialLink[] | never[] | undefined> {
    return this.socialLinkWebService.getSocialLinks()
  }
}
