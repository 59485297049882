<div class="card p-3 pt-4 pb-1">
  <div class="text-center">
    <ngx-skeleton-loader appearance="circle" [theme]="{
      'width': '100px',
      'height': '100px'
    }"></ngx-skeleton-loader>
  </div>

  <div class="text-center mt-3">
    <ngx-skeleton-loader [theme]="{
      'width': '150px',
      'height': '28px'
    }"></ngx-skeleton-loader>
  </div>

  <ngx-skeleton-loader count="3"></ngx-skeleton-loader>
</div>
