<h6 class="text-center mt-4">Career path</h6>

<ng-container *ngIf="!!workStations">
  <div *ngFor="let station of workStations" class="card p-3 pb--0 pt-2">
    <blockquote class="blockquote">
      <p>{{ station.CompanyName }}</p>
    </blockquote>
    <figcaption class="blockquote-footer mb-2">
      {{ station.StartYear }} - {{ (station.EndYear ? station.EndYear : 'now') }}
    </figcaption>

    <p class="mt-0">{{ station.Description }}</p>

    <p class="mb-0">
      <span class="fw-bold">Skills:</span>
      <br />
      <span *ngFor="let skill of getSkillsArray(station.Skills)" class="badge bg-secondary me-1">{{ skill }}</span>
    </p>
  </div>
</ng-container>

<ng-container *ngIf="!workStations">
  <fbe-career-path-skeleton></fbe-career-path-skeleton>
</ng-container>
