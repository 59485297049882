import { Injectable } from "@angular/core"
import { ProjectWebService } from "./web-services/project-web.service"
import { Project } from "../data/Project"

@Injectable()
export class ProjectService {
  constructor(
    private projectWebService: ProjectWebService
  ) {
  }

  public getProjects(): Promise<Project[] | never[] | undefined> {
    return this.projectWebService.getProjects()
  }
}
