<div class="card p-3 pt-2 pb-0">
  <div class="text-center">
    <ngx-skeleton-loader count="5" appearance="circle"></ngx-skeleton-loader>
    <br />
    <ngx-skeleton-loader [theme]="{
      'width': '180px',
      'height': '40px',
      'margin-top': '15px'
    }"></ngx-skeleton-loader>
  </div>
</div>
