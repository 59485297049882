import { Injectable } from "@angular/core";
import { BaseWebService } from "./base-web.service"
import { Project } from "src/app/data/Project"

@Injectable()
export class ProjectWebService extends BaseWebService {
  public getProjects(): Promise<Project[] | never[] | undefined> {
    return super.get<Project[] | never[] | undefined>('/project/projects')
      .then(r => {
        if (!r) {
          return []
        }

        r.forEach(station => Object.setPrototypeOf(station, new Project))
        return r
      })
  }
}
