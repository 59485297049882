import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { catchError, retry } from 'rxjs/operators'
import { throwError } from 'rxjs';

@Injectable()
export abstract class BaseWebService {
    private readonly apiUrl: string = 'https://rest.florian-berger.info'

    constructor(
        private http: HttpClient
    ) { }
    
    protected get<TData>(endpoint: string): Promise<TData | undefined> {
      return this.http
        .get<TData>(this.apiUrl + endpoint)
        .pipe(retry(1), catchError(this.handleError)).toPromise();
    }

    private handleError(error: any) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }

      console.error(errorMessage);
      
      return throwError(() => {
        return errorMessage;
      });
    }
}
