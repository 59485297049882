import { Injectable } from "@angular/core"
import { General } from "../data/General";
import { GeneralWebService } from "./web-services/general-web.service";

@Injectable()
export class GeneralService {
  constructor(
    private generalWebService: GeneralWebService
  ) {
  }

  public getGeneralInfo(): Promise<General | undefined> {
    return this.generalWebService.getGeneralInfo()
  }

  public getContactMail(): Promise<string | undefined> {
    return this.generalWebService.getConcactMail()
  }
}
