import { Component } from "@angular/core"

@Component({
  selector: 'fbe-career-path-skeleton',
  templateUrl: './career-path-skeleton.component.html',
  styleUrls: [ './../career-path.component.scss' ]
})
export class CareerPathSkeletonComponent {

}
