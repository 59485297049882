import { Component, Input } from "@angular/core"
import { SocialLink } from "../data/SocialLink"
import { GeneralService } from "../services/general.service"
import { ToastrService } from "ngx-toastr"

@Component({
  selector: 'fbe-socials',
  templateUrl: './socials.component.html',
  styleUrls: [ './socials.component.scss' ]
})
export class SocialsComponent {
  @Input() socials: SocialLink[] | undefined

  constructor(
    private generalService: GeneralService,
    private toastr: ToastrService
  ) {
  }

  public async sendMail() {
    let contactMail: string | undefined = '' 
    try {
      contactMail = await this.generalService.getContactMail()
    } catch (err) {
      console.error(err)
      contactMail = undefined
    }
    
    if (!contactMail) {
      this.toastr.error('Email address could not be loaded. Please try again later or use another contact method.')
      return
    }

    location.href = `mailto:${contactMail}`
  }
}
