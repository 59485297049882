import { AfterViewInit, Component } from '@angular/core'
import { GeneralService } from './services/general.service'
import { WorkStationService } from './services/work-station.service'
import { General } from './data/General'
import { WorkStation } from './data/WorkStation'
import { SocialLink } from './data/SocialLink'
import { SocialLinkService } from './services/social-link.service'
import { Project } from './data/Project'
import { ProjectService } from './services/project.service'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'fbe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  public generalInfo: General | undefined
  public workStations: WorkStation[] | never[] | undefined
  public socialLinks: SocialLink[] | never[] | undefined
  public projects: Project[] | never[] | undefined

  constructor(
    private generalService: GeneralService,
    private workStationService: WorkStationService,
    private socialLinkService: SocialLinkService,
    private projectService: ProjectService,
    private toastr: ToastrService
  ) {
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      let [general, workStations, socialLinks, projects] = await Promise.all([
        this.generalService.getGeneralInfo(),
        this.workStationService.getWorkStations(),
        this.socialLinkService.getSocialLinks(),
        this.projectService.getProjects()
      ])
  
      this.generalInfo = general
      this.workStations = workStations
      this.socialLinks = socialLinks
      this.projects = projects
    } catch (err) {
      console.error(err)
      this.toastr.error('There was an error loading the data. Please try again later.')
    }
  }
}
