<h6 class="text-center mt-4">Personal projects</h6>

<ng-container *ngIf="!!projects">
  <div *ngFor="let project of projects" class="card p-3 pb--0 pt-2">
    <blockquote class="blockquote">
      <p>
        {{ project.DisplayName }}
        <a *ngIf="!!project.TargetLink" class="btn btn-sm btn-light" href="{{ project.TargetLink }}" target="_blank" rel="noopener">
          <i class="fa fa-external-link" aria-hidden="true"></i>
        </a>
      </p>
    </blockquote>
    <figcaption class="blockquote-footer mb-2">
      {{ project.Description }}
    </figcaption>

    <p class="mb-0">
      <span class="fw-bold">Tech Stack:</span>
      <br />
      <span *ngFor="let skill of getTechStackArray(project.TechStack)" class="badge bg-secondary me-1">{{ skill }}</span>
    </p>
  </div>
</ng-container>

<ng-container *ngIf="!projects">
  <fbe-projects-skeleton></fbe-projects-skeleton>
</ng-container>
