import { Injectable } from "@angular/core";
import { BaseWebService } from "./base-web.service"
import { SocialLink } from "src/app/data/SocialLink"

@Injectable()
export class SocialLinkWebService extends BaseWebService {
  public getSocialLinks(): Promise<SocialLink[] | never[] | undefined> {
    return super.get<SocialLink[] | never[] | undefined>('/social/links')
      .then(r => {
        if (!r) {
          return []
        }

        r.forEach(station => Object.setPrototypeOf(station, new SocialLink))
        return r
      })
  }
}
