import { Component } from "@angular/core"

@Component({
  selector: 'fbe-general-info-skeleton',
  templateUrl: './general-info-skeleton.component.html',
  styleUrls: [ './../general-info.component.scss' ]
})
export class GeneralInfoSkeletonComponent {

}
