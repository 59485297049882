import { Injectable } from "@angular/core"
import { BaseWebService } from "./base-web.service"
import { General } from "src/app/data/General"

@Injectable()
export class GeneralWebService extends BaseWebService {
  public getGeneralInfo(): Promise<General | undefined> {
    return super.get('/general')
      .then(r => Object.setPrototypeOf(r, new General))
  }

  public getConcactMail(): Promise<string | undefined> {
    return super.get('/general/contact')
  }
}
