import { Component, Input } from "@angular/core"
import { Project } from "../data/Project"

@Component({
  selector: 'fbe-projects',
  templateUrl: './projects.component.html',
  styleUrls: [ './projects.component.scss' ]
})
export class ProjectsComponent {
  @Input() projects: Project[] | never[] | undefined
  
  public getTechStackArray(techStack: string): string[] {
    return techStack.split(',').map(s => s.trim())
  }
}
