<ng-container *ngIf="!!generalData">
  <div class="card p-3 pt-4">
    <div class="text-center">
      <img id="profile-image" [src]="generalData.AvatarData | safe:'resourceUrl'" class="rounded-circle" />
    </div>
  
    <div class="text-center mt-3">
      <h5 class="mt-1 mb-0">{{ generalData.Name }}</h5>
      <span>{{ generalData.JobTitle }}</span>

      <div class="pt-3 mt-2">
        <p class="description-text">
          <markdown [data]="generalData.Description"></markdown>
        </p>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!generalData">
  <fbe-general-info-skeleton></fbe-general-info-skeleton>
</ng-container>
