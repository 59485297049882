import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { ToastrModule } from 'ngx-toastr'
import { MarkdownModule } from 'ngx-markdown';

import { HttpClientModule } from '@angular/common/http'
import { GeneralWebService } from './services/web-services/general-web.service'
import { GeneralService } from './services/general.service'
import { WorkStationWebService } from './services/web-services/work-station-web.service'
import { WorkStationService } from './services/work-station.service'
import { SocialLinkWebService } from './services/web-services/social-link-web.service'
import { SocialLinkService } from './services/social-link.service'

import { GeneralInfoComponent } from './general-info/general-info.component'
import { GeneralInfoSkeletonComponent } from './general-info/skeleton/general-info-skeleton.component'
import { CareerPathComponent } from './career-path/career-path.component'
import { CareerPathSkeletonComponent } from './career-path/skeleton/career-path-skeleton.component'
import { SocialsComponent } from './socials/socials.component'
import { SocialsSkeletonComponent } from './socials/skeleton/socials-skeleton.component'
import { ProjectsComponent } from './projects/projects.component'
import { ProjectsSkeletonComponent } from './projects/skeleton/projects-skeleton.component'
import { ProjectWebService } from './services/web-services/project-web.service'
import { ProjectService } from './services/project.service'
import { SafePipe } from './shared/pipes/safe.pipe';

@NgModule({
  declarations: [
    AppComponent,

    // General Info
    GeneralInfoComponent,
    GeneralInfoSkeletonComponent,

    // Career Path
    CareerPathComponent,
    CareerPathSkeletonComponent,

    // Socials
    SocialsComponent,
    SocialsSkeletonComponent,

    // Projects
    ProjectsComponent,
    ProjectsSkeletonComponent,

    // Pipes
    SafePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true
    }),
    MarkdownModule.forRoot()
  ],
  providers: [
    // Web Services
    GeneralWebService,
    WorkStationWebService,
    SocialLinkWebService,
    ProjectWebService,

    // Services
    GeneralService,
    WorkStationService,
    SocialLinkService,
    ProjectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
