<div class="container py-5">
  <div class="row d-flex justify-content-center">
    <div class="col-md-7">
      <fbe-general-info [generalData]="generalInfo"></fbe-general-info>
      <fbe-career-path [workStations]="workStations"></fbe-career-path>
      <fbe-projects [projects]="projects"></fbe-projects>
      <fbe-socials [socials]="socialLinks"></fbe-socials>
    </div>
  </div>
</div>
