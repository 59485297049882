import { Injectable } from "@angular/core";
import { BaseWebService } from "./base-web.service"
import { WorkStation } from "src/app/data/WorkStation";

@Injectable()
export class WorkStationWebService extends BaseWebService {
  public getWorkStations(): Promise<WorkStation[] | never[] | undefined> {
    return super.get<WorkStation[] | never[] | undefined>('/work/stations')
      .then(r => {
        if (!r) {
          return []
        }

        r.forEach(station => Object.setPrototypeOf(station, new WorkStation))
        return r
      })
  }
}
