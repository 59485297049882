import { Component } from "@angular/core"

@Component({
  selector: 'fbe-projects-skeleton',
  templateUrl: './projects-skeleton.component.html',
  styleUrls: [ './../projects.component.scss' ]
})
export class ProjectsSkeletonComponent {

}
