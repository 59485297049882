import { Component, Input } from "@angular/core"
import { General } from "../data/General"

@Component({
  selector: 'fbe-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: [ './general-info.component.scss' ]
})
export class GeneralInfoComponent {
  @Input() generalData: General | undefined
}
