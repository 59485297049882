import { Injectable } from "@angular/core"
import { WorkStationWebService } from "./web-services/work-station-web.service";
import { WorkStation } from "../data/WorkStation";

@Injectable()
export class WorkStationService {
  constructor(
    private workStationWebService: WorkStationWebService
  ) {
  }

  public getWorkStations(): Promise<WorkStation[] | never[] | undefined> {
    return this.workStationWebService.getWorkStations()
  }
}
