import { Component, Input } from "@angular/core"
import { WorkStation } from "../data/WorkStation"

@Component({
  selector: 'fbe-career-path',
  templateUrl: './career-path.component.html',
  styleUrls: [ './career-path.component.scss' ]
})
export class CareerPathComponent {
  @Input() workStations: WorkStation[] | undefined = undefined

  public getSkillsArray(skills: string): string[] {
    return skills.split('-').map(s => s.trim())
  }
}
