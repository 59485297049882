<h6 class="text-center mt-4">Socials &amp; Contact</h6>

<ng-container *ngIf="!!socials">
  <div class="card p-3 pt-2">
    <div class="text-center">
      <ul class="social-list">
        <li *ngFor="let social of socials">
          <a title="{{ social.Name }}" href="{{ social.TargetLink }}" target="_blank" rel="noopener">
            <i class="fa {{ social.IconClassName }}"></i>
          </a>
        </li>
      </ul> 
      <div class="buttons">
        <button class="btn btn-primary px-4" type="button" (click)="sendMail()">Contact via email</button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!socials">
  <fbe-socials-skeleton></fbe-socials-skeleton>
</ng-container>
