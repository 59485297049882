<div class="card p-3 pt-4 pb-1">
  <ngx-skeleton-loader [theme]="{
    'width': '100px',
    'height': '28px'
  }"></ngx-skeleton-loader>

  <ngx-skeleton-loader [theme]="{
    'width': '150px',
    'height': '22px',
    'margin-top': '-10px'
  }"></ngx-skeleton-loader>

  <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
</div>
