import { Component } from "@angular/core";

@Component({
  selector: 'fbe-socials-skeleton',
  templateUrl: './socials-skeleton.component.html',
  styleUrls: [ './../socials.component.scss' ]
})
export class SocialsSkeletonComponent {

}
